import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from 'components/interface/button';
import FileInput from 'components/interface/fileInput';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';

type FormInputs = {
  file: File;
};

type UploadCampaignContactsFormProps = {
  campaignId: string;
  onSubmitCallback: () => void;
};

const UploadCampaignContactsForm: React.FC<UploadCampaignContactsFormProps> = ({
  campaignId,
  onSubmitCallback,
}) => {
  const { uploadCampaignContactsFile } = useCampaign();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data, event: any) => {
    if (isLoading) return;
    setIsLoading(true);

    // Get form data
    const formData = new FormData();
    if (event.target.file.files[0]) {
      formData.append('file', event.target.file.files[0]);
    }

    // Send request to API conditional to create or update
    const response = await uploadCampaignContactsFile(campaignId, formData);

    // Manage response errors or success
    if (response?.errors) {
      if (response.errors?.file) {
        setError('file', {
          type: 'manual',
          message: response.errors.file,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al subir el archivo, verifica el formato');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Contactos agregados correctamente');
      } else {
        toast.error('Error al subir el archivo, verifica el formato');
        reset();
      }
      onSubmitCallback();
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType={'multipart/form-data'}>
      <FileInput
        allowedExtensions={['.csv']}
        error={errors.file?.message}
        {...register('file')}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 16,
          gap: 8,
        }}
      >
        <Button
          label={'Cancelar'}
          variant={'secondary'}
          onClick={() => {
            onSubmitCallback();
            reset();
          }}
        />
        <Button
          type={'submit'}
          label={'Guardar'}
          variant={'primary'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default UploadCampaignContactsForm;
