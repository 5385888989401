import React, { useState, useEffect } from 'react';

import { UUID } from 'crypto';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Button from 'components/interface/button';
import FileInput from 'components/interface/fileInput';
import TemplatePreview from 'features/templates/components/templatePreview';

import { useInbox } from 'features/inbox/hooks/useInbox';

import {
  type Template,
  type TemplateVariable,
} from 'features/templates/types/templateTypes';

import './styles.css';

type TemplateVariablesStepProps = {
  template: Template;
  headerVariables: TemplateVariable[];
  bodyVariables: TemplateVariable[];
  chatbotId: UUID;
  integrationId: string;
  canNext: boolean;
  onNext: () => void;
  onBack: () => void;
  setHeaderVariables: (variables: TemplateVariable[]) => void;
  setBodyVariables: (variables: TemplateVariable[]) => void;
};

const TemplateVariablesStep: React.FC<TemplateVariablesStepProps> = ({
  template,
  headerVariables,
  bodyVariables,
  chatbotId,
  integrationId,
  canNext = false,
  onNext = () => {},
  onBack = () => {},
  setHeaderVariables = () => {},
  setBodyVariables = () => {},
}) => {
  const { uploadMessageMedia, getMessageMediaBinary } = useInbox();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    setHeaderVariables([]);
    setBodyVariables([]);

    const headerComponent = template?.components.find(
      (c) => c.type === 'HEADER',
    );
    if (headerComponent?.example) {
      const headerVariable: TemplateVariable = {
        type: headerComponent?.format ?? 'TEXT',
      };
      if (headerComponent?.format === 'TEXT') {
        headerVariable.text = '';
      } else {
        headerVariable.src = undefined;
        headerVariable.file = undefined;
      }
      setHeaderVariables([headerVariable]);
    }

    const bodyComponent = template?.components.find((c) => c.type === 'BODY');
    const bodyComponentVariables = Array.from(
      { length: bodyComponent?.example?.body_text?.[0]?.length ?? 0 },
      () => {
        return {
          type: 'TEXT',
          text: '',
        } as TemplateVariable;
      },
    );
    setBodyVariables(bodyComponentVariables);
  }, [template]);

  const handleNext = async () => {
    if (headerVariables.length > 0) {
      const headerVariableAux = { ...headerVariables[0] };
      if (
        (headerVariableAux.type === 'IMAGE' ||
          headerVariableAux.type === 'DOCUMENT') &&
        headerVariableAux.file
      ) {
        setIsUploading(true);

        const formData = new FormData();
        formData.append('file', headerVariableAux.file);

        const uploadResponse = await uploadMessageMedia(
          chatbotId,
          integrationId,
          formData,
        );

        if (uploadResponse?.mediaId) {
          const downloadResponse = await getMessageMediaBinary(
            chatbotId,
            integrationId,
            uploadResponse?.mediaId,
          );
          headerVariableAux.src = `data:${downloadResponse?.mimeType};base64,${downloadResponse?.binary}`;
          headerVariableAux.text = uploadResponse?.mediaName;
          headerVariableAux.id = uploadResponse?.mediaId;
        }
        setHeaderVariables([headerVariableAux]);
        setIsUploading(false);
      }
    }
    onNext();
  };

  return (
    <>
      <div className={'template-variables-step-container'}>
        {(headerVariables.length > 0 || bodyVariables.length > 0) && (
          <div className={'template-variables-step-variables'}>
            {headerVariables.length > 0 && (
              <div>
                <Text variant={'b2'} weight={500}>
                  Encabezado
                </Text>
                {headerVariables.map((v, i) => {
                  if (v.type === 'IMAGE') {
                    return (
                      <FileInput
                        key={i}
                        allowedExtensions={['.jpg', '.jpeg', '.png', '.gif']}
                        onChange={(e) => {
                          const headerVariablesAux = [...headerVariables];
                          headerVariablesAux[i].file = e.target.files?.[0];
                          setHeaderVariables(headerVariablesAux);
                        }}
                      />
                    );
                  }
                  if (v.type === 'DOCUMENT') {
                    return (
                      <FileInput
                        key={i}
                        allowedExtensions={['.pdf', '.doc', '.docx', '.csv']}
                        onChange={(e) => {
                          const headerVariablesAux = [...headerVariables];
                          headerVariablesAux[i].file = e.target.files?.[0];
                          setHeaderVariables(headerVariablesAux);
                        }}
                      />
                    );
                  }
                  return (
                    <Input
                      key={i}
                      placeholder={`{{${i + 1}}}`}
                      onChange={(e) => {
                        const headerVariablesAux = [...headerVariables];
                        headerVariablesAux[i].text = e.target.value;
                        setHeaderVariables(headerVariablesAux);
                      }}
                    />
                  );
                })}
              </div>
            )}
            {bodyVariables.length > 0 && (
              <div>
                <Text variant={'b2'} weight={500}>
                  Cuerpo
                </Text>
                {bodyVariables.map((_, i) => {
                  return (
                    <Input
                      key={i}
                      placeholder={`{{${i + 1}}}`}
                      onChange={(e) => {
                        const bodyVariablesAux = [...bodyVariables];
                        bodyVariablesAux[i].text = e.target.value;
                        setBodyVariables(bodyVariablesAux);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
        <TemplatePreview
          templateData={template}
          headerVariables={headerVariables}
          bodyVariables={bodyVariables}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button label={'Atrás'} variant={'secondary'} onClick={onBack} />
        <Button
          label={'Siguiente'}
          isDisabled={!canNext}
          isLoading={isUploading}
          onClick={handleNext}
        />
      </div>
    </>
  );
};

export default TemplateVariablesStep;
