import React, { useState, useEffect, useRef } from 'react';

import Text from 'components/interface/text';
import Modal from 'components/interface/modal';
import Image from 'components/interface/image';
import HoverMenu from 'components/interface/hoverMenu';
import NavigatorLink from '../sidebarLink';

import { type HoverMenuOption } from 'components/interface/hoverMenu';

import { useAuth } from 'features/auth/hooks/useAuth';
import { useUser } from 'features/user/hooks/useUser';
import { useDashboard } from 'features/dashboard/hooks/useDashboard';
import { useOrganization } from 'features/organization/hooks/useOrganization';

type NavigatorProfileProps = {
  isExpanded?: boolean;
};

const NavigatorProfile: React.FC<NavigatorProfileProps> = ({ isExpanded }) => {
  const { authLogout } = useAuth();
  const { userDisplayData } = useUser();
  const { organizationData } = useOrganization();
  const { clientSocketStatus, setActiveTab } = useDashboard();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleWhatsappSupport = () => {
    const link = `https://api.whatsapp.com/send?phone=5218714830632&text=Buen%20d%C3%ADa%2C%0A%0ASoy%20${userDisplayData.displayName}%20de%20${organizationData.name}%20y%20tengo%20una%20duda%20con%20respecto%20a%20Altur.`;
    window.open(link, '_blank');
  };

  const MENU_OPTIONS: HoverMenuOption[] = [
    {
      label: 'Configuración',
      icon: 'gear',
      onClick: () => setActiveTab('settings'),
    },
    {
      label: 'Chatear con soporte',
      icon: 'whatsapp',
      onClick: handleWhatsappSupport,
    },
  ];

  const MENU_LOGOUT_OPTION: HoverMenuOption = {
    label: 'Cerrar sesión',
    icon: 'signOut',
    onClick: () => setIsConfirmModalOpen(true),
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <>
      <div
        ref={menuRef}
        className="navigator-profile"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className="navigator-profile-info">
          <div
            className={`navigator-profile-info-avatar ${
              clientSocketStatus.webSocketConnecting
                ? 'npia-connecting'
                : clientSocketStatus.webSocketConnected
                ? 'npia-connected'
                : 'npia-disconnected'
            }`}
          >
            <Image
              src={userDisplayData.profilePicture}
              skeletonHeight={32}
              skeletonWidth={32}
            />
            <div />
          </div>
          <div>
            <Text variant={'h8'} numberOfLines={1}>
              {userDisplayData.displayName}
            </Text>
            <Text variant={'b3'} color={'var(--gray)'} numberOfLines={1}>
              {userDisplayData.role
                ? organizationData.roles[userDisplayData.role].name
                : 'Sin organización'}
            </Text>
          </div>
        </div>
        <div className="navigator-profile-small">
          <NavigatorLink
            name={'Perfil'}
            icon={'user'}
            isExpanded={isExpanded}
          />
        </div>
        <HoverMenu
          isOpen={isMenuOpen}
          options={MENU_OPTIONS}
          bottomOption={MENU_LOGOUT_OPTION}
        />
      </div>
      <Modal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        title={'Log out'}
      >
        <Text variant={'b2'}>
          {'Confirma que deseas cerrar sesión en Altur'}
        </Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 8,
            marginTop: 8,
          }}
        >
          <button
            type={'button'}
            className={'button button-secondary'}
            onClick={() => setIsConfirmModalOpen(false)}
          >
            <Text variant={'b2'}>Cancelar</Text>
          </button>
          <button
            type={'button'}
            className={'button button-danger'}
            onClick={authLogout}
          >
            <Text variant={'b2'}>Cerrar sesión</Text>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default NavigatorProfile;
