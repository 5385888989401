import React, { useState, useEffect } from 'react';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import Image from 'components/interface/image';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type Message } from 'features/inbox/types/inboxTypes';
import {
  type TemplateComponent,
  type TemplateButton,
} from 'features/templates/types/templateTypes';

import './styles.css';

/**
 * MessageContentTemplate
 * @description Component to display a template in a message's content
 * @param {Message} message - message object
 * @returns {TSX.Element} MessageContentTemplate component
 */

type MessageContentTemplateProps = {
  message: Message;
};

const MessageContentTemplate: React.FC<MessageContentTemplateProps> = ({
  message,
}) => {
  const { inbox, getMessageMediaBinary } = useInbox();

  const [templateData, setTemplateData] = useState<any>(null);
  const [headerComponent, setHeaderComponent] =
    useState<TemplateComponent | null>(null);
  const [bodyComponent, setBodyComponent] = useState<TemplateComponent | null>(
    null,
  );
  const [footerComponent, setFooterComponent] =
    useState<TemplateComponent | null>(null);
  const [buttons, setButtons] = useState<TemplateButton[]>([]);

  const [mediaUrl, setMediaUrl] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const template: any = JSON.parse(message.content);
    const templateComponents: TemplateComponent[] = template?.components;

    const componentHeader =
      templateComponents?.find((component) => component.type === 'HEADER') ??
      null;
    const componentBody =
      templateComponents?.find((component) => component.type === 'BODY') ??
      null;
    const componentFooter =
      templateComponents?.find((component) => component.type === 'FOOTER') ??
      null;
    const componentButtons =
      templateComponents?.find((component) => component.type === 'BUTTONS')
        ?.buttons ?? [];

    if (componentHeader?.format === 'TEXT') {
      let headerTextAux = componentHeader?.text ?? '';
      template?.headerValues?.forEach((v: string, i: number) => {
        headerTextAux = headerTextAux.replace(
          `{{${i + 1}}}`,
          v ?? `{{${i + 1}}}`,
        );
      });
      componentHeader.text = headerTextAux;
    }
    if (componentHeader?.format === 'IMAGE') {
      if (template?.headerValues[0]?.type === 'id') {
        handleRenderImage(template?.headerValues[0]?.media ?? '');
      } else {
        const url = new URL(template?.headerValues[0]?.media ?? '');
        setMediaUrl(url.origin + url.pathname);
      }
    }
    if (componentBody?.text) {
      let bodyTextAux = componentBody?.text ?? '';
      template?.bodyValues?.forEach((v: string, i: number) => {
        bodyTextAux = bodyTextAux.replace(`{{${i + 1}}}`, v ?? `{{${i + 1}}}`);
      });
      componentBody.text = bodyTextAux;
    }

    setTemplateData(template);
    setHeaderComponent(componentHeader);
    setBodyComponent(componentBody);
    setFooterComponent(componentFooter);
    setButtons(componentButtons);
  }, [message]);

  const handleRenderImage = async (mediaId: string) => {
    if (!inbox.activeThread || mediaUrl.length > 0) return;
    const mediaData = await getMessageMediaBinary(
      inbox.activeThread.chatbotId,
      inbox.activeThread.integrationId,
      mediaId,
    );

    setMediaUrl(`data:${mediaData?.mimeType};base64,${mediaData?.binary}`);
  };

  const handleDownloadFile = async () => {
    if (!inbox.activeThread || isLoading) return;
    setIsLoading(true);

    const mediaId = templateData?.headerValues[0]?.media;

    const mediaData = await getMessageMediaBinary(
      inbox.activeThread.chatbotId,
      inbox.activeThread.integrationId,
      mediaId,
    );

    const url = `data:${mediaData?.mimeType};base64,${mediaData?.binary}`;
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', 'documento');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);

    setIsLoading(false);
  };

  return (
    <>
      <div className={'inbox-message-template'}>
        {headerComponent && (
          <div className={'inbox-message-template__header'}>
            {headerComponent.format === 'IMAGE' && (
              <div>
                <Image
                  src={mediaUrl}
                  skeletonWidth={320}
                  skeletonHeight={124}
                />
              </div>
            )}
            {headerComponent.format === 'DOCUMENT' && (
              <div onClick={() => handleDownloadFile()}>
                <div className={'inbox-message-template__header__file'}>
                  <Icon name={'file'} size={18} color={'var(--gray)'} />
                  <Text variant={'b3'} color={'var(--gray)'} numberOfLines={2}>
                    Documento Adjunto
                  </Text>
                </div>
              </div>
            )}
            {headerComponent.format === 'TEXT' && (
              <Text
                variant={'b2'}
                weight={500}
                style={{ padding: 4, paddingBottom: 0 }}
              >
                {headerComponent?.text}
              </Text>
            )}
          </div>
        )}
        {bodyComponent && (
          <div className={'inbox-message-template__body'}>
            <Text variant={'b2'}>{bodyComponent.text}</Text>
          </div>
        )}
        {footerComponent && (
          <div className={'inbox-message-template__footer'}>
            <Text variant={'s2'}>{footerComponent?.text}</Text>
          </div>
        )}
        {buttons.length > 0 && (
          <div className={'inbox-message-template-buttons'}>
            {buttons.map((button: TemplateButton, i) => {
              return (
                <div key={i} className={'inbox-message-template-button'}>
                  <Text
                    variant={'b2'}
                    align={'center'}
                    color={'var(--gray)'}
                    numberOfLines={1}
                  >
                    {button.text}
                  </Text>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default MessageContentTemplate;
