/**
 * capitalize
 * @description Capitalizes first letter of string
 * @param {String} str
 * @returns {String}
 */
export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * waidToPhoneNumber
 * @description Converts a whatsapp id to a phone number
 * @param {String} id
 * @returns {String}
 * @example
 * waidToPhoneNumber('5218714830632') // (+52) 871-483-0632
 */
export const waidToPhoneNumber = (id: string): string => {
  try {
    const country = id.slice(0, 2);
    const areaCode = id.slice(3, 6);
    const firstPart = id.slice(6, 9);
    const secondPart = id.slice(9, 13);

    return `(+${country}) ${areaCode} ${firstPart} ${secondPart}`;
  } catch (error) {
    return id;
  }
};

/**
 * formatPhoneNumber
 * @description Formats a ISO e.164 phone number to a human readable format
 * @param {String} phoneNumber
 * @returns {String}
 * @example
 * formatPhoneNumber('+528714830632') // (+52) 871 483 0632
 * formatPhoneNumber('+525524830632') // (+52) 55 1483 0632
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
  try {
    const twoDigitAreaCodes = ['55', '81', '33'];

    if (twoDigitAreaCodes.includes(phoneNumber.slice(3, 5))) {
      const country = phoneNumber.slice(1, 3);
      const areaCode = phoneNumber.slice(3, 5);
      const firstPart = phoneNumber.slice(5, 9);
      const secondPart = phoneNumber.slice(9, 13);

      return `(+${country}) ${areaCode} ${firstPart} ${secondPart}`;
    }

    const country = phoneNumber.slice(1, 3);
    const areaCode = phoneNumber.slice(3, 6);
    const firstPart = phoneNumber.slice(6, 9);
    const secondPart = phoneNumber.slice(9, 13);

    return `(+${country}) ${areaCode} ${firstPart} ${secondPart}`;
  } catch (error) {
    return phoneNumber;
  }
};
