import React, { useState, useEffect } from 'react';

import { ResponsiveFunnel } from '@nivo/funnel';

import Text from 'components/interface/text';
import Card from 'components/interface/card';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import { type CampaignStats } from 'features/campaigns/providers/CampaignProvider';

import { theme } from 'themes/nivo';

import './styles.css';

type CampaignFunnelGraphProps = {
  campaignId: string;
};

const STEP_LABELS = {
  phonecall: [
    'Llamadas detonadas',
    'Conectadas',
    'Buzón',
    'Cliente habló',
    'Conversión',
  ],
  whatsapp: ['Enviados', 'Recibidos', 'Leídos', 'Respondidos', 'Conversión'],
};

/**
 * CampaignFunnelGraph
 * @description Description of CampaignFunnelGraph
 * @param {string} campaignId - Campaign ID
 * @param {CampaignStats} campaignStats - CampaignStats object
 * @returns {TSX.Element} CampaignFunnelGraph component
 */

const CampaignFunnelGraph: React.FC<CampaignFunnelGraphProps> = ({
  campaignId,
}) => {
  const { campaignsData, getCampaignStats } = useCampaign();
  const [labels] = useState<string[]>(
    campaignsData[campaignId].integrationType ===
      'bots | Integration Phone Call'
      ? STEP_LABELS.phonecall
      : STEP_LABELS.whatsapp,
  );
  const [data, setData] = useState<
    { id: string; value: number; percentage: number; label: string }[]
  >(() => {
    const initialData = [
      {
        id: 'step_sent',
        value: 0,
        percentage: -1,
        label: labels[0],
      },
      {
        id: 'step_delivered',
        value: 0,
        percentage: 0,
        label: labels[1],
      },
      {
        id: 'step_voicemail',
        value: 0,
        percentage: 0,
        label: labels[2],
      },
      {
        id: 'step_responded',
        value: 0,
        percentage: 0,
        label: labels[3],
      },
    ];

    const campaignStatsObject = campaignsData[campaignId]
      .campaignStats as CampaignStats;
    if (campaignStatsObject?.converted > 0) {
      initialData.push({
        id: 'step_converted',
        value: campaignStatsObject.converted,
        percentage: campaignStatsObject.convertedRate ?? 0,
        label: labels[4],
      });
    }

    return initialData;
  });

  useEffect(() => {
    const campaignIntegrationType =
      campaignsData[campaignId].integrationType ===
      'bots | Integration Phone Call'
        ? 'phonecall'
        : 'whatsapp';

    const loadCampaignStats = async () => {
      await getCampaignStats(campaignId);
      if (Object.keys(campaignsData[campaignId].campaignStats).length > 0) {
        const dataAux = data;
        const campaignStatsObject = campaignsData[campaignId]
          .campaignStats as CampaignStats;
        if (campaignIntegrationType === 'phonecall') {
          dataAux[0].value = campaignStatsObject?.sent ?? 0;
          dataAux[1].value = campaignStatsObject?.delivered ?? 0;
          dataAux[1].percentage = campaignStatsObject?.deliveryRate ?? 0;
          dataAux[2].value = campaignStatsObject?.voicemail ?? 0;
          dataAux[2].percentage = campaignStatsObject?.voicemailRate ?? 0;
          dataAux[3].value = campaignStatsObject?.replied ?? 0;
          dataAux[3].percentage = campaignStatsObject?.repliedRate ?? 0;
        } else if (campaignIntegrationType === 'whatsapp') {
          dataAux[0].value = campaignStatsObject?.sent ?? 0;
          dataAux[1].value = campaignStatsObject?.delivered ?? 0;
          dataAux[1].percentage = campaignStatsObject?.deliveryRate ?? 0;
          dataAux[2].value = campaignStatsObject?.read ?? 0;
          dataAux[2].percentage = campaignStatsObject?.readRate ?? 0;
          dataAux[3].value = campaignStatsObject?.replied ?? 0;
          dataAux[3].percentage = campaignStatsObject?.repliedRate ?? 0;
        }
        if (campaignStatsObject?.converted > 0) {
          dataAux[4] = {
            id: 'step_converted',
            value: campaignStatsObject?.converted ?? 0,
            percentage: campaignStatsObject?.convertedRate ?? 0,
            label: 'Conversión',
          };
        }
        setData([...dataAux]);
      }
    };

    loadCampaignStats();
  }, [campaignId]);

  return (
    <div style={{ marginTop: 24 }}>
      <Card>
        <div className={'campaign-funnel-graph-container'}>
          <div className={'campaign-funnel-graph-labels'}>
            {data.map((item) => (
              <div key={item.id}>
                <Text variant={'h5'} color={'var(--gray)'} formatter={'number'}>
                  {item.value}
                </Text>
                <Text variant={'b3'} weight={500}>
                  {item.label}
                </Text>
                {item.percentage >= 0 && (
                  <Text
                    variant={'b3'}
                    color={'var(--gray)'}
                    formatter={'percentage'}
                    decimalPlaces={0}
                    weight={400}
                    style={{ marginTop: 4 }}
                  >
                    {item.percentage}
                  </Text>
                )}
              </div>
            ))}
          </div>
          <div className={'campaign-funnel-graph-plot'}>
            <ResponsiveFunnel
              data={data}
              theme={theme}
              margin={{ top: 32, right: 0, bottom: 40, left: 0 }}
              direction={'horizontal'}
              shapeBlending={0.6}
              colors={['#e6f0ff', '#99c2ff', '#4e94ff', '#0066FF', '#003399']}
              borderWidth={8}
              borderOpacity={1}
              enableLabel={false}
              beforeSeparatorLength={0}
              beforeSeparatorOffset={0}
              afterSeparatorLength={0}
              afterSeparatorOffset={0}
              isInteractive={false}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CampaignFunnelGraph;
