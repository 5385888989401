import React, { useState, useEffect } from 'react';
import { DataType, Table } from 'ka-table';
import { SortingMode } from 'ka-table/enums';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import CampaignContactStatus from '../campaignContactStatus';
import CampaignContactsAddFromList from '../campaignContactsAddFromList';
import CampaignContactsUpload from '../campaignContactsUpload';
import CampaignContactsDownload from '../campaignContactsDownload';
import EmptyState from 'components/interface/emptyState';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';

import {
  type CampaignData,
  type CampaignStats,
} from 'features/campaigns/providers/CampaignProvider';

import { formatDateTime } from 'utils/dates';

import './styles.css';

type CampaignContactsProps = {
  campaignId: string;
  canUpdate: boolean;
};

/** // TODO: ADD REMOTE PAGINATION AND SORTING
 * TODO: ADD SEARCH BAR FILTERING (not so important)
 * CampaignContacts
 * @description Component that displays the contacts of a campaign in a table
 * @param {string} campaignId - Campaign id
 * @param {boolean} canUpdate - If the user can update the campaign
 * @returns {TSX.Element} CampaignContacts component
 */

const CampaignContacts: React.FC<CampaignContactsProps> = ({
  campaignId,
  canUpdate,
}) => {
  const { campaignsData, getCampaignContacts, getCampaignStats } =
    useCampaign();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [sortBy, setSortBy] = useState('lastUpdate');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [isLoading, setIsLoading] = useState(false);

  const campaignData: CampaignData = campaignsData[campaignId];

  useEffect(() => {
    const totalContacts =
      (campaignData?.campaignStats as CampaignStats)?.contacts ?? 0;
    const pageCount = Math.ceil(totalContacts / pageSize);
    setPageCount(pageCount);
    handleGetCampaignContacts();
  }, [campaignData.campaignStats]);

  const handleGetCampaignContacts = async () => {
    if (isLoading) return;
    setIsLoading(true);

    // TODO: ADD REMOTE PAGINATION AND SORTING
    /* Backend pagination and sorting is already implemented
       
    The problem is in the ka-table component (research needed)

    Also, if possible, make loading state more elegant

    https://komarovalexander.github.io/ka-table/#/sorting */

    await getCampaignContacts(
      campaignId,
      0,
      (campaignData.campaignStats as CampaignStats)?.contacts ?? 0,
      sortBy,
      sortOrder,
    );

    setIsLoading(false);
  };

  return (
    <div>
      <div className={'campaign-contacts-header'}>
        <div className={'campaign-contacts-header-left'}>
          <Icon name={'users'} size={18} />
          <Text variant={'h7'}>Contactos</Text>
        </div>
        <div className={'campaign-contacts-header-right'}>
          {canUpdate && (
            <>
              <CampaignContactsAddFromList
                campaignId={campaignId}
                onSubmitCallback={() => {
                  setTimeout(() => {
                    handleGetCampaignContacts();
                    getCampaignStats(campaignId);
                  }, 1000);
                }}
              />
              <CampaignContactsUpload
                campaignId={campaignId}
                onSubmitCallback={() => {
                  setTimeout(() => {
                    handleGetCampaignContacts();
                    getCampaignStats(campaignId);
                  }, 1000);
                }}
              />
            </>
          )}
          <CampaignContactsDownload campaignId={campaignId} />
        </div>
      </div>
      <div className={'campaign-contacts-table'}>
        <Table
          rowKeyField={'contact'}
          columns={[
            {
              key: 'fId',
              dataType: DataType.String,
              title: 'ID',
              style: { width: 80 },
            },
            {
              key: 'contact',
              dataType: DataType.String,
              title: 'Contacto',
              style: { width: 140 },
            },
            {
              key: 'name',
              dataType: DataType.String,
              title: 'Nombre',
              style: { width: 200 },
            },
            {
              key: 'context',
              dataType: DataType.String,
              title: 'Contexto',
            },
            {
              key: 'status',
              dataType: DataType.String,
              title: 'Estado',
              style: { width: 140 },
            },
            {
              key: 'retries',
              dataType: DataType.Number,
              title: 'Reintentos',
              style: { width: 140, textAlign: 'center' },
            },
            {
              key: 'lastUpdate',
              dataType: DataType.Date,
              title: 'Última actualización',
              style: { width: 200, textAlign: 'right' },
            },
          ]}
          data={campaignData?.campaignContacts}
          paging={
            {
              enabled: true,
              pageIndex: pageIndex,
              pageSize: pageSize,
              pageCount: pageCount,
            } as any
          }
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'fId':
                    return (
                      <Text variant={'b3'} numberOfLines={1}>
                        {props.value}
                      </Text>
                    );
                  case 'contact':
                    return (
                      <Text variant={'b3'} color={'var(--gray)'}>
                        {props.value}
                      </Text>
                    );
                  case 'name':
                    return (
                      <Text variant={'b3'} numberOfLines={1}>
                        {props.value}
                      </Text>
                    );
                  case 'context':
                    return (
                      <Text
                        variant={'b3'}
                        color={'var(--gray)'}
                        numberOfLines={1}
                      >
                        {props.value}
                      </Text>
                    );
                  case 'status':
                    return (
                      <CampaignContactStatus status={props.value as string} />
                    );
                  case 'lastUpdate':
                    return (
                      <Text
                        variant={'b3'}
                        color={'var(--gray)'}
                        align={'right'}
                      >
                        {formatDateTime(props.value)}
                      </Text>
                    );
                }
              },
            },
          }}
          sortingMode={SortingMode.Single}
        />
      </div>
      {campaignData?.campaignContacts?.length === 0 && (
        <div style={{ marginBottom: 80 }}>
          <EmptyState
            text={'No hay contactos'}
            iconName={'users'}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignContacts;
