import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';
import CampaignCardOptions from 'features/dashboard/features/campaigns/components/campaignCardOptions';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';
import { useUser } from 'features/user/hooks/useUser';

import {
  type CampaignData,
  type CampaignStats,
} from 'features/campaigns/providers/CampaignProvider';

import './styles.css';

type CampaignCardProps = {
  campaign: CampaignData;
};

const CampaignCard: React.FC<CampaignCardProps> = ({ campaign }) => {
  const navigate = useNavigate();
  const { chatbotsData } = useChatbot();
  const { getCampaignStats } = useCampaign();
  const { userHasPermission } = useUser();

  const [campaignIntegration, setCampaignIntegration] =
    useState<string>('WhatsApp');
  const [formattedDate, setFormattedDate] = useState<string>('');

  const getStats = async () => {
    await getCampaignStats(campaign.id);
  };

  useEffect(() => {
    if (Object.keys(campaign.campaignStats).length === 0) {
      getStats();
    }
    if (campaign.integrationType === 'bots | Integration Whatsapp') {
      setCampaignIntegration('WhatsApp');
    }
    if (campaign.integrationType === 'bots | Integration Phone Call') {
      setCampaignIntegration('Llamadas');
    }

    const date = new Date(campaign.createdAt);
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    setFormattedDate(formattedDate);
  }, [campaign]);

  const handleClick = () => {
    navigate(`/dashboard/campaigns/${campaign.id}`);
  };

  return (
    <div className={'campaign-card'} onClick={handleClick}>
      <div className={'campaign-card__header'}>
        <div>
          <Text
            variant={'s2'}
            weight={500}
            color={
              campaignIntegration === 'WhatsApp'
                ? 'var(--integration-whatsapp)'
                : 'var(--integration-phone-call)'
            }
          >
            {campaignIntegration}
          </Text>
          <Text variant={'h7'}>{campaign.name}</Text>
          <Text variant={'b3'} color={'var(--gray)'}>
            {campaign.description}
          </Text>
        </div>
        {userHasPermission('campaigns.update') && (
          <CampaignCardOptions campaignId={campaign.id} />
        )}
      </div>
      <div className={'campaign-card__body'}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <div className={'campaign-card__bot'}>
            <Icon name={'robot'} color={'var(--gray)'} />
            <Text variant={'b3'} weight={400} color={'var(--gray)'}>
              {chatbotsData[campaign.botId]?.nickname}
            </Text>
          </div>
          <div className={'campaign-card__bot'}>
            <Icon name={'calendarBlank'} color={'var(--gray)'} />
            <Text variant={'b3'} weight={400} color={'var(--gray)'}>
              {formattedDate}
            </Text>
          </div>
        </div>
        <div className={'campaign-card__completion'}>
          <div
            style={{
              width: `${
                (campaign.campaignStats as CampaignStats)?.completionRate ?? 0
              }%`,
              backgroundColor: `${
                campaign.status === 'active' ? 'var(--success)' : 'var(--gray)'
              }`,
            }}
          />
        </div>
        <div className={'campaign-card__completion__text'}>
          <div className={'campaign-card__state'}>
            <div
              style={{
                backgroundColor: `var(--${campaign.status})`,
                borderColor: `var(--${campaign.status}-pale)`,
              }}
            ></div>
            <Text variant={'b3'} color={'var(--gray)'}>
              {campaign.status}
            </Text>
          </div>

          <Text variant={'b3'} weight={400} color={'var(--gray)'}>
            {(campaign.campaignStats as CampaignStats)?.completed ?? 0} /{' '}
            {(campaign.campaignStats as CampaignStats)?.contacts ?? 0}
          </Text>
        </div>
      </div>
      <div className={'campaign-card__footer'}></div>
    </div>
  );
};

export default CampaignCard;
