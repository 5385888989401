import React, { FC, createContext, useReducer, useMemo } from 'react';

import { UUID } from 'crypto';

import campaignReducer from './campaignReducer';

export type CampaignSchedulingDays =
  | 'mon'
  | 'tue'
  | 'wed'
  | 'thu'
  | 'fri'
  | 'sat'
  | 'sun';

export type CampaignSchedulingDay = {
  active: boolean;
  startTime: string;
  stopTime: string;
};

export type CampaignScheduling = {
  [key in CampaignSchedulingDays]: CampaignSchedulingDay;
};

export type CampaignStats = {
  contacts: number;
  sent: number;
  delivered: number;
  voicemail: number;
  read: number;
  converted: number;
  replied: number;
  failed: number;
  rejected: number;
  completed: number;
  completionRate: number;
  sentRate: number;
  failedRate: number;
  deliveryRate: number;
  voicemailRate: number;
  readRate: number;
  rejectionRate: number;
  repliedRate: number;
  convertedRate: number;
};

export type CampaignContact = {
  campaignId: number;
  fId: string;
  contact: string;
  name: string;
  context: string;
  status: string;
  retries: number;
  lastUpdate: string;
};

export type CampaignData = {
  id: string;
  name: string;
  description: string;
  botId: UUID;
  integrationId: string;
  integrationType: string;
  status: string;
  scheduling: CampaignScheduling;
  tz: string;
  createdAt: string;
  retries: number;
  template: string;
  templateAlt: string;
  templateImage: string;
  firstMessage: string;
  messageLimit: number;
  archived: boolean;
  campaignStats: CampaignStats | {};
  campaignContacts: CampaignContact[];
};

export type CampaignsData = {
  [key: string]: CampaignData;
};

export type CampaignsState = {
  campaignsData: CampaignsData;
  saveCampaignsData: (campaigns: CampaignsData) => void;
};

type CampaignProviderProps = {
  children: React.ReactNode;
};

export const CampaignContext = createContext<CampaignsState>({
  campaignsData: {},
  saveCampaignsData: () => {},
});

const CampaignProvider: FC<CampaignProviderProps> = ({ children }) => {
  const initialState: CampaignsState = {
    campaignsData: {},
    saveCampaignsData: (campaigns: CampaignsData) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(campaignReducer, initialState);

  const saveCampaignsData = (campaignsData: CampaignsData) => {
    dispatch({ type: 'SAVE_CAMPAIGNS_DATA', payload: campaignsData });
  };

  const contextValue = useMemo(() => {
    return {
      campaignsData: state.campaignsData,
      saveCampaignsData,
    };
  }, [state.campaignsData]);

  return (
    <CampaignContext.Provider value={contextValue}>
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignProvider;
